import React from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { Wrapper } from "./wrapper";

const ImageBlock = () => {
  const data = useStaticQuery(graphql`
    query promotionImage {
      image: file(relativePath: { eq: "stapel-v18.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Img fluid={data.image.childImageSharp.fluid} />
    </Wrapper>
  );
};

export default ImageBlock;
