import React from "react";
import { Link } from "gatsby";
import { Wrapper, Title, Heading, Paragraph, Stock } from "./wrapper";

const Card = ({ item, thumbnail, slug, postClass }) => {
  const { heading, paragraph, stock, title } = item;

  return (
    <Wrapper>
      <article
        className={`post-card ${postClass} ${
          thumbnail ? `with-image` : `no-image`
        }`}
        style={
          thumbnail && {
            backgroundImage: `url(${thumbnail.childImageSharp.fluid.src})`
          }
        }
      >
        <Link to={slug} className="post-card-link">
          <div className="post-card-content">
            <Title className="post-card-title">{title || slug}</Title>
          </div>
        </Link>
      </article>
      <div>
        <Heading>{heading}</Heading>
        <Paragraph>{paragraph}</Paragraph>
        <Stock>{stock}</Stock>
      </div>
    </Wrapper>
  );
};
export default Card;
