import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";

import "../utils/normalize.css";
import "../utils/css/screen.css";

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>
            Vår målsättning är att fem miljoner kronor ska ha kommit in till
            klubben på födelsedagen 2021. Då ska vi samlas vid Karlsrofältet
            igen. För Blåvitt får aldrig dö.
          </h2>
          <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.image.childImageSharp.fluid}
              className="kg-image"
            />
          </figure>
          <h3 id="dynamic-styles">Då ska vi samlas vid Karlsrofältet igen</h3>
          <p>
            Coronapandemin har inneburit en enorm ekonomisk utmaning för IFK
            Göteborg. Vi vet inte när läget blir normalt igen. Men vi vet att
            klubben kommer att behöva vår hjälp för att gå vidare.
          </p>
          <p>
            Den fjärde oktober är årets vackraste dag för alla blåvita. Det var
            den dagen som ett gäng unga Annedalsgrabbar slöt sig samman för att
            bilda den finaste av alla kamratföreningar. Den fjärde oktober 2020
            påbörjar vi ett årslångt projekt i deras anda.
          </p>
          <p>
            För att få fira många fler födelsedagar ihop. Inom Fjärde
            oktoberrörelsen kan du välja mellan flera olika sätt att skänka
            pengar till IFK Göteborg. Vår målsättning är att fem miljoner kronor
            ska ha kommit in till klubben på födelsedagen 2021. Då ska vi samlas
            vid Karlsrofältet igen. För Blåvitt får aldrig dö. SUPPORTERKLUBBEN
          </p>
          <p>ÄNGLARNA – KOMMANDOBRYGGAN</p>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    image: file(relativePath: { eq: "om.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
);
