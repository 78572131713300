import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({ votesA, votesB }) => {
  return (
    <Bar
      data={{
        labels: ["Team Tomte", "Team Änglarna"],
        datasets: [
          {
            label: "Antal röster",
            data: [votesA, votesB],
            backgroundColor: ["rgb(29, 66, 138)", "red"],
            borderColor: [""]
          }
        ]
      }}
      height={110}
    />
  );
};

export default BarChart;
