import React from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { Wrapper, ImageWrapper } from "./wrapper";

const Sweaters = () => {
  const allImagesQuery = graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "Jul" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `;

  const {
    allFile: { edges: images }
  } = useStaticQuery(allImagesQuery);

  return (
    <>
      <h1 style={{ textAlign: "center" }}>Rösta på årets jultröjor!</h1>
      <Wrapper>
        {images.map((image, index) => {
          return (
            <ImageWrapper key={index}>
              <Img fluid={image.node.childImageSharp.fluid} />
            </ImageWrapper>
          );
        })}
      </Wrapper>
    </>
  );
};

export default Sweaters;
