import React from "react";
import Vote from "../components/Voting";
import Layout from "../components/layout";
import Sweaters from "../components/Sweaters";

import styled from "styled-components";

const jultroja = () => {
  const Repeat = styled.div`
    & + & {
      margin-top: 6em;
    }
  `;

  return (
    <Layout>
      <Repeat>
        <Sweaters />
      </Repeat>
      <Repeat>
        <Vote />
      </Repeat>
    </Layout>
  );
};

export default jultroja;
