import styled from "styled-components";

export const Wrapper = styled("div")`
  @media (max-width: 850px) {
    margin-bottom: 35px;
  }
`;

export const Title = styled("h2")`
  margin: 0;
  display: inline-block;
  font-size: 3.4rem;
  max-width: 70%;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.33, 0, 0.2, 1);

  &:hover {
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.33, 0, 0.2, 1);
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const Heading = styled("h4")`
  word-break: keep-all;
  font-size: 32px;
  line-height: 1.333;
  font-weight: bold;
  color: #000;
  font-family: "Merriweather", Georgia, Serif;
  font-size: 21px;
  font-family: "Merriweather", Georgia, Serif;
  margin-bottom: 10px;
  transition: color 0.3s ease-in-out;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  white-space: normal;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const Paragraph = styled("p")`
  margin: 0;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  white-space: normal;
  overflow: hidden;
  font-size: 16px;
  margin-bottom: 10px;
  color: #73737d;
  display: box;
  max-width: 515px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const Stock = styled("p")`
  margin: 1em;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fcbd03;
`;
