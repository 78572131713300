import React from "react";
import styled from "styled-components";

export const Wrapper = styled("div")`
  width: 100%;
  height: 60vh;
  color: #1d428a;
  align-items: center;
  padding: 16px;
  background-color: #1d428a;
`;

const TextBlock = ({ content }) => {
  return (
    <Wrapper className="row">
      <p
        style={{
          textDecorationColor: "#FCBD03",
          color: "white",
          textAlign: "center",
          fontWeight: "300",
          fontSize: "24px",
          lineHeight: "32px",
          margin: "0"
        }}
      >
        {content}
      </p>
    </Wrapper>
  );
};

export default TextBlock;
