import React from "react";
import Video from "../../components/Video/";

const Hero = () => {
  // const data = useStaticQuery(graphql`
  //   query Images {
  //     image: file(relativePath: { eq: "heroimage.png" }) {
  //       id
  //       childImageSharp {
  //         fixed(width: 400) {
  //           ...GatsbyImageSharpFixed
  //         }
  //         fluid {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //     logo: file(relativePath: { eq: "logo.png" }) {
  //       id
  //       childImageSharp {
  //         fixed(width: 400) {
  //           ...GatsbyImageSharpFixed
  //         }
  //         fluid {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `);

  return (
    <div style={{ position: "relative" }}>
      <Video heading="När alla änglar blir en" />
      {/* <div className="header-img">
        <Img
          style={{ filter: "brightness(0.5)" }}
          fluid={data.image.childImageSharp.fluid}
        />
      </div>
      <div className="logo-img">
        <Img fluid={data.logo.childImageSharp.fluid} />
      </div>
      <div className="hero-content">
        <h1
          style={{
            color: "#fff",
            whiteSpace: "no-wrap",
            textTransform: "uppercase",
            fontWeight: 400,
            margin: 0
          }}
        >
          Fjärde
        </h1>
        <h1
          style={{
            color: "#fff",
            whiteSpace: "no-wrap",
            textTransform: "uppercase",
            fontWeight: 400,
            margin: 0
          }}
        >
          Oktoberrörelsen
        </h1>
        <h2
          style={{
            color: "#FCBD03",
            whiteSpace: "no-wrap",
            textTransform: "uppercase",
            fontWeight: 400,
            margin: 0
          }}
        >
          När alla änglar blir en
        </h2>
      </div> */}
    </div>
  );
};

export default Hero;
