import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/Card";
import Hero from "../components/Hero/";
import TextBlock from "../components/TextBlock/";
import ImageBlock from "../components/ImageBlock/";
import Vote from "../components/Voting/";
import { ColumnRow, ColumnRowItem } from "../components/ColumnRow";
import Sweaters from "../components/Sweaters";
import "../utils/normalize.css";
import "../utils/css/screen.css";

export const Repeat = styled.div`
  & + & {
    margin-top: 6em;
  }
`;

const BlogIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const siteDescription = data.site.siteMetadata.description;
  const posts = data.allMarkdownRemark.edges;
  let postCounter = 0;

  const itemPairs = posts.reduce((result, value, index, array) => {
    if (index % 2 === 0) {
      result.push(array.slice(index, index + 2));
    }
    return result;
  }, []);

  const wide = "1fr";
  const narrow = "657px";

  const Grid = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: ${props =>
      props.hasOnlyOnePost
        ? "1fr"
        : props.reverse
        ? `${wide} ${narrow}`
        : `${narrow} ${wide}`};
    grid-template-rows: 2;
    column-gap: 30px;
    margin-bottom: 75px;

    @media (max-width: 980px) {
      grid-template-columns: 100%;
      margin-bottom: 0;
    }
  `;

  return (
    <Layout title={siteTitle} description={siteDescription}>
      <SEO
        title="4okt"
        keywords={[
          `ifk`,
          `blåvitt`,
          `ifk göteborg`,
          `änglarna, ifk gotebborg, anglarna`
        ]}
      />
      <Repeat>
        <Hero />
      </Repeat>
      <Repeat>
        <ColumnRow>
          <ColumnRowItem>
            <TextBlock
              content="
            Fjärde oktoberrörelsen är en kampanj som sträcker sig mellan 4 oktober
            2020 och 4 oktober 2021. Målet är att ge IFK Göteborg ekonomiska
            förutsättningar att överleva bortom coronapandemin.

            SWISH: 123 617 42 05
            "
            />
          </ColumnRowItem>
        </ColumnRow>
      </Repeat>
      <Repeat>
        <ImageBlock />
      </Repeat>
      <Repeat>
        <div className="post-feed" style={{ display: "block" }}>
          {itemPairs.map((ip, index) => {
            const hasOnlyOnePost = ip.length === 1;
            const isOdd = index % 2 !== 0;
            const isEven = index % 2 !== 1;

            return (
              <Grid
                className="grid-layout"
                reverse={isEven}
                hasOnlyOnePost={hasOnlyOnePost}
              >
                <Card
                  key={index}
                  item={ip[0].node.frontmatter}
                  slug={ip[0].node.fields.slug}
                  postClass={`post`}
                  narrow={isEven}
                  count={postCounter}
                  thumbnail={ip[0].node.frontmatter.thumbnail}
                />
                {ip[1] && (
                  <Card
                    item={ip[1].node.frontmatter}
                    slug={ip[1].node.fields.slug}
                    postClass={`post`}
                    narrow={isOdd}
                    count={postCounter}
                    thumbnail={ip[1].node.frontmatter.thumbnail}
                  />
                )}
              </Grid>
            );
          })}
        </div>
      </Repeat>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            color
            description
            paragraph
            heading
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
);
