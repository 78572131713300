import styled from "styled-components";

export const Wrapper = styled("div")`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  &::after {
    content: "";
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }
`;

export const VideoPlayer = styled("video")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Heading = styled("h2")`
  position: absolute;
  color: white;
  z-index: 1000;
  font-weight: 300;
  letter-spacing: 0.1em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 100%;
  text-align: center;

  @media screen and (min-width: 25em) {
    font-size: calc(16px + (24 - 16) * (100vw - 400px) / (800 - 400));
  }

  /* Safari <8 and IE <11 */
  @media screen and (min-width: 25em) {
    font-size: calc(16px + (24 - 16) * (100vw - 400px) / (800 - 400));
  }

  @media screen and (min-width: 50em) {
    font-size: calc(16px + (24 - 16) * (100vw - 400px) / (800 - 400));
  }
`;
