import styled, { css } from "styled-components";

export const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 900px;
  margin: auto;

  @media (max-width: 590px) {
    justify-content: center;
  }
`;

export const ButtonReset = styled("button")`
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-size: 1em;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid black;
`;

const ButtonBase = styled(ButtonReset)`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 24px 64px;
  font-size: 1.3rem;
  line-height: 1.2;
  text-decoration: none;
  transform-origin: 50% 50%;
  transition: 200ms ease, transform 300ms cubic-bezier(0.47, 1.64, 0.41, 0.8);

  :hover {
    text-decoration: underline;
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #eee;
    opacity: 0;
    transition: opacity 150ms ease;
  }

  /* :hover {
    :before {
      opacity: 0.8;
    }
  } */
`;

export const ButtonSubmit = styled(ButtonBase)`
  background-color: black;
  color: white;
  width: 100%;
`;

export const Button = styled(ButtonBase)`
  min-width: 260px;
  margin-bottom: 32px;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: lightgrey;
      border: lightgrey;
      cursor: not-allowed;
    `}

  ${({ isActive }) =>
    isActive === "red" &&
    css`
      background-color: red;
      color: white;
      font-style: italic;
    `}

  ${({ isActive }) =>
    isActive === "blue" &&
    css`
      background-color: rgb(29, 66, 138);
      color: white;
      font-style: italic;
    `}
`;
