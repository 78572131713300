import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";

const CustomButton = styled("div")`
  border: 2px solid black;
  padding: 32px;
  background: black;
  color: white;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const url = post.frontmatter.url;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article
          className={`post-content ${post.frontmatter.thumbnail || `no-image`}`}
        >
          <header className="post-content-header">
            <h1 className="post-content-title">"{post.frontmatter.title}"</h1>
          </header>
          {post.frontmatter.description && (
            <p class="post-content-excerpt">{post.frontmatter.description}</p>
          )}
          {post.frontmatter.thumbnail && (
            <div className="post-content-image">
              <Img
                className="kg-image"
                fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
                alt={post.frontmatter.title}
              />
            </div>
          )}
          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          {(post.frontmatter.ticket || post.frontmatter.ifkshop) && (
            <div
              style={{ margin: "auto", textAlign: "center", maxWidth: "320px" }}
            >
              <a href={url} target="_blank" rel="noopener noreferrer">
                <CustomButton>Köp</CustomButton>
              </a>
            </div>
          )}
          {post.frontmatter.subscriber && (
            <div
              style={{ margin: "auto", textAlign: "center", maxWidth: "320px" }}
            >
              <a href={url} target="_blank" rel="noopener noreferrer">
                <CustomButton>Bli månadsgivare</CustomButton>
              </a>
            </div>
          )}
          <footer className="post-content-footer" />
        </article>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        url
        subscriber
        ticket
        ifkshop
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
