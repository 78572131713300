import styled from "styled-components";

export const ColumnRow = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ColumnRowItem = styled.div`
  flex: 1 1 50%;

  & + & {
    padding-left: 2.6rem;
  }
`;
