import React from "react";
import ang from "../../../content/assets/ang.mp4";
import { Wrapper, VideoPlayer, Heading } from "./wrapper";

const Video = ({ heading }) => (
  <Wrapper>
    <VideoPlayer
      className="video"
      height="100%"
      width="100%"
      loop
      muted
      autoPlay
    >
      <source src={ang} type="video/mp4" />
    </VideoPlayer>
    {heading && <Heading>{heading}</Heading>}
  </Wrapper>
);
export default Video;
