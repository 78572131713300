import React, { useState, useEffect } from "react";
import axios from "axios";
import Result from "./Result";
import { ButtonWrapper } from "./wrapper";
import { supabase } from "../../utils/client";
import { Button, ButtonSubmit } from "./wrapper";
import styled from "styled-components";

export const Repeat = styled.div`
  & + & {
    margin-top: 6em;
  }
`;

const angel = (
  <i>
    <u style={{ color: "red" }}>Ängel</u>
  </i>
);

const tomte = (
  <i>
    <u style={{ color: "rgb(29, 66, 138)" }}>Tomte</u>
  </i>
);

const Vote = () => {
  const [voted, setVoted] = useState(false);
  const [votes, setVotes] = useState([]);
  const [vote, setVote] = useState({ shirtA: 0, shirtB: 0 });
  const [submitted, setSubmitted] = useState(false);
  const [ip, setIP] = useState("");
  const [userDidVote, setUserDidVote] = useState();

  const { shirtA, shirtB } = vote;

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      getData();
    } else {
      setIP(localStorage.getItem("user"));
    }
  }, [ip]);

  useEffect(() => {
    if (localStorage.getItem("user") && localStorage.getItem("did-vote")) {
      setUserDidVote(true);
    }
  }, [userDidVote]);

  useEffect(() => {
    const localS = localStorage.getItem("did-vote");
    const uIp = localStorage.getItem("user");

    fetchVotes();

    if (!!localS === true && uIp === ip) {
      setUserDidVote(true);
    }
  }, [ip]);

  async function fetchVotes() {
    const { data } = await supabase.from("votes", "key").select("*");
    setVotes(data);
  }

  async function createVote() {
    await supabase
      .from("votes")
      .insert([{ shirtA, shirtB }])
      .single();
    setVote({ shirtA: 0, shirtB: 0 });
    fetchVotes();
    setVoted(false);
    setSubmitted(true);

    localStorage.setItem("did-vote", true);
    localStorage.setItem("user", ip);

    setTimeout(() => {
      window.location.reload();
    }, 800);
  }

  const totalVotesA = votes
    .map(item => item.shirtA)
    .reduce((prev, curr) => prev + curr, 0);

  const totalVotesB = votes
    .map(item => item.shirtB)
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <div>
      <Repeat>
        <h3 style={{ textAlign: "center" }}>
          Är du team {tomte} eller team {angel}. Välj sida och skicka in ditt
          svar
        </h3>
        <br />
        <ButtonWrapper>
          <Button
            disabled={submitted || userDidVote}
            isActive={shirtA > 0 && voted && "blue"}
            value={shirtA}
            onClick={() => [
              setVote({ ...vote, shirtA: +1, shirtB: 0 }),
              setVoted(true)
            ]}
          >
            Team Tomte
          </Button>
          <Button
            disabled={submitted || userDidVote}
            isActive={shirtB > 0 && voted && "red"}
            value={shirtB}
            onClick={() => [
              setVote({ ...vote, shirtB: +1, shirtA: 0 }),
              setVoted(true)
            ]}
          >
            Team Ängel
          </Button>
          <ButtonSubmit
            disabled={!voted || userDidVote ? true : false}
            type="submit"
            onClick={() => createVote()}
          >
            Skicka ditt svar
          </ButtonSubmit>
          <p
            style={{
              textAlign: "center",
              marginTop: "16px",
              width: "100%"
            }}
          >
            {submitted
              ? "Tack för din röst!"
              : userDidVote && "Du har redan röstat"}
          </p>
        </ButtonWrapper>
      </Repeat>
      <Repeat>
        <Result totalVotesA={totalVotesA} totalVotesB={totalVotesB} />
      </Repeat>
    </div>
  );
};

export default Vote;
