import styled from "styled-components";

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: row;
`;

export const ImageWrapper = styled("div")`
  width: 50%;
  :nth-child(2) {
    margin-right: 16px;
  }
`;
